import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios'

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      code: '',
      file: null,
      response: null,
      error: null
    }
  }

  onFileChange = event => {
    // Update the state
    this.setState({ file: event.target.files[0] });
  };

  handleChangeInput = (e) => {
    this.setState({code: e.target.value});
  }

  // On file upload (click the upload button)
  onSubmit = () => {
    // Create an object of formData
    let formData = new FormData();
  
    // Update the formData object
    formData.append('code', this.state.code);

    formData.append(
      "file",
      this.state.file,
      this.state.file.name
    );
  
    // Details of the uploaded file
    console.log(this.state.selectedFile);
  
    // Request made to the backend api
    // Send formData object
    axios.post(process.env.REACT_APP_API_URL, formData).then(response => {
      if (response.status === 200) {
        if (response.data.status === 'success') {
          this.setState({response: response.data});
        } else {
          this.setState({error: response.data.message})
        }
      } else {
        this.setState({error: response.message})
      }
    });
  };

  render() {
    return (
      <div className="container">
        <div className="py-5 text-center">
          <h2>Dummy App</h2>
          <p className="lead">Just for dummy.</p>
        </div>

        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="row">
                <div className="col-sm-3 form-label">Item Code</div>
                <div className="col-sm-9">
                  <input id="code" name="code" className="form-control" defaultValue={this.state.code} onChange={this.handleChangeInput.bind(this)} />
                </div>
              </div>
              <div className="row"><div className="col-sm-12">&nbsp;</div></div>
              <div className="row">
                <div className="col-sm-3 form-label">File</div>
                <div className="col-sm-9">
                  <input type="file" className="form-control" onChange={this.onFileChange} />
                </div>
              </div>
              <div className="row"><div className="col-sm-12">&nbsp;</div></div>
              <div className="row">
                <div className="col-sm-3">&nbsp;</div>
                <div className="col-sm-9">
                  <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {this.state.response?
        <div className="row">
          <div className="col-md-12">
            Result:
          </div>
          <div className="col-md-3">
            Code:
          </div>
          <div className="col-md-9">
            {this.state.response.data.code}
          </div>
          <div className="col-md-3">
            File:
          </div>
          <div className="col-md-9">
            {this.state.response.data.file}
          </div>
        </div>
        :''}

        {this.state.error?
        <div className="row">
          <div className="col-md-12">{this.state.error}</div>
        </div>
        :''}
      </div>
    )
  }
}

export default App;
